body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.itemContainer {
  background-color: rgb(65, 43, 7);
}

.itemContainer h1 {
  text-align: center;
  color: whitesmoke;
  margin-top: 50px;
  padding: 10px;
}

.mensaje {
  background-color: aliceblue;
  text-align: center;
  padding: 20px;
}
.texto {
  background-color: aliceblue;
  text-align: center;
  padding: 20px;
}
.texto1 {
  display: inline-block;
  margin: 15px;
}
.texto2 {
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.itemCount {
  background-color: rgb(65, 43, 7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contador {
  background-color: aliceblue;
  color: black;
  border-radius: 30px;
}
.contador:hover {
  background-color: grey;
}

.elH1 {
  background-color: rgb(65, 43, 7);
  text-align: center;
  color: whitesmoke;
  margin-top: 50px;
  padding: 10px;
}

.footer {
  height: 200px;
  background-color: #6d4c41;
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 899px) {
  .firstLogo {
    display: none;
  }
}

.imgCard {
  border-radius: 15px;
}

.imgCard:hover {
  margin-top: 15px;
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.9;
}

@media only screen and (max-width: 899px) {
  .imageHome {
    width: 100%;
    height: auto;
  }
}

.cardsHome {
  height: 50%;
  margin: 3px;
}

.containerItemList {
  height: 80vh;
  background-image: url(https://img.freepik.com/free-photo/mid-section-male-baker-holding-paper-bag-with-breads_23-2147883355.jpg?w=1060&t=st=1664820586~exp=1664821186~hmac=bc7e01466de035a41e62e1647d9e841453bc368bcf0d642f2d4e5e2a3c065cea);
  background-repeat: repeat-x;
  background-size: cover;
}

@media (min-width: 900px) {
  .containerItemList {
    height: 115vh;
  }
}

@media (max-width: 700px) {
  .containerItemList {
    height: 85vh;
    padding-top: 5px;
  }
}


.containerCart{
  margin: 175px; 
  margin-top: 0px;

}

@media (max-width: 990px) {
  .containerCart {
    margin: 100px;
    margin-top: 15px
  }
}
@media (max-width: 793px) {
  .containerCart {
    margin: 0px;
    margin-top: 15px
  }
}

@media (max-width: 600px) {
  .containerCart {
    margin: 85px;
    margin-top: 15px
  }
}
@media (max-width: 550px) {
  .containerCart {
    margin: 30px;
    margin-top: 15px
  }
}

@media (max-width: 500px) {
  .containerCart {
    margin: 15px;
    margin-top: 10px
  }
}
.checkoutBox{
margin: 150px 300px;
padding-top: 20px;
background: linear-gradient(90deg, rgba(201,201,201,1) 3%, rgba(237,236,236,1) 15%, rgba(237,236,236,1) 85%, rgba(201,201,201,1) 97%);
}

@media (max-width: 1250px) {
  .checkoutBox {
    margin: 150px 200px;
  }
}
@media (max-width: 1150px) {
  .checkoutBox {
    margin: 150px 100px;
  }
}
@media (max-width: 910px) {
  .checkoutBox {
    margin: 150px 0px;
  }
}

.inputBox{
  margin-bottom: 25px;
  margin-top: 30px;
}

.finalMessage1{
  padding-top: 10px;
  text-align: center;
  margin: 15px 20px;
  margin-left: 0px;
}
.finalMessage2{
  margin: 15px 50px;
  text-align: center;
margin-left: 0px;
}

@media (max-width: 600px) {
  .finalMessage1 {
   padding: 10px 50px;
  }
}
.finalMessageBox{
  background: linear-gradient(90deg, rgba(201,201,201,1) 3%, rgba(237,236,236,1) 15%, rgba(237,236,236,1) 85%, rgba(201,201,201,1) 97%);
  margin: 125px 150px;
  border-radius: 10px;
}
@media (max-width: 600px) {
  .finalMessageBox {
   margin: 25px 30px;
   padding: 0px;
  }
}
.message{
  margin: 0px 50px;
}